import * as React from "react"
import DownloadFilePage from "@/components/DownloadFilePage"



const Index = () => (
  <>
    <DownloadFilePage
      downloadURL="https://nreal-public.nreal.ai/web/support/nreal-light/Nreal_Light_After_Sales_&_Warranty_US.pdf"
    />
  </>
)

export default Index